<template>
  <div>
  <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                  :curr-page.sync="pagingParams.currPage"
                  :per-page.sync="pagingParams.perPage"
                  :per-page-list="perPageList"
                  :pages="pages"
                  :search-bar-place-holder="searchBarPlaceHolder"
                  :keyword.sync="pagingParams.keyword">
      <template #tools>
        <CButton color="info" size="sm" class="m-1" @click="specFirmware" :disabled="!selected">指定</CButton>

      </template>

  </MyBeeSearchBar>
  <BeeGridTable
      ref="bee"
      border
      stripe
      :showFilter="false"
      :columns="columns"
      :loading="isLoading"
      :data="rows"
      :showPager="false"
      :maxHeight="gridCardHeight"
      highlightRow
      no-data-text="データがありません。"
      no-filtered-data-text=""
      :context="context"
      :span-method="customSpan"
      :showVerticalScrollBar="false"
      :showHorizontalScrollBar="false"
      @on-selection-change="selectionChanged"
      >
    <!-- HEADER ソート可能にするために 必要-->

    <template slot-scope="{ column,index }" slot="idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="gwNowFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="gwSpecFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <template slot-scope="{ column,index }" slot="patientNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
    <!-- cell -->
    <template slot-scope="{ row,index }" slot="id"><div class="text-left">{{row.id}}</div></template>
    <template slot-scope="{ row,index }" slot="serialNumber"><div class="text-left">{{row.serialNumber}}</div></template>
    <template slot-scope="{ row,index }" slot="gwNowFirmwareId"><div class="text-left">{{row.gwNowFirmwareId}}</div></template>
    <template slot-scope="{ row,index }" slot="gwSpecFirmwareId"><div class="text-left">{{row.gwSpecFirmwareId}}</div></template>
    <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{row.created}}</div></template>
    <template slot-scope="{ row,index }" slot="patientId"><div class="text-left">{{row.patientId}}</div></template>
    <template slot-scope="{ row,index }" slot="patientName"><div class="text-left">{{row.patientName}}</div></template>
  </BeeGridTable>
  </div>
</template>

<script>


import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
    name: 'GatewayForFirmware',
    components:{
      MyBeeHeader, MyBeeSearchBar
    },
    mixins: [BeeMix],
      props: {
        isShow: { default: false, type: Boolean },
        firmwareId: { required:true, type: Number },
      },
      mounted() {
        this.goodPaging();
      },
      data: () => {
        return {
          columns: [
            {type: "selection", width: 60, maxWidth: 50,   align: "center", },
            {title: 'IoTゲートウェイID', key: 'id',slot:'id',headSlot:"idHead"},
            {title: 'シリアル番号', key: 'serialNumber',slot:'serialNumber',headSlot:"serialNumberHead"},
            {title: 'ファームウェアID', key: 'gwNowFirmwareId',slot:'gwNowFirmwareId',headSlot:"gwNowFirmwareIdHead"},
            {title: '指定ファームウェアID', key: 'gwSpecFirmwareId',slot:'gwSpecFirmwareId',headSlot:"gwSpecFirmwareIdHead"},
            {title: '登録日時', key: 'created',slot:'created',headSlot:"createdHead"},
            {title: '登録No', key: 'patientId',slot:'patientId',headSlot:"patientIdHead"},
            {title: '患者名', key: 'patientName',slot:'patientName',headSlot:"patientNameHead"},
            {title: ' ', key: 'after',slot:'after'}
          ],
          searchBarPlaceHolder:"シリアル番号で検索",
          isCollapsed:true,
          selected:false,
        }
      },
      methods: {
        selectionChanged:function(){
          // this.selected = this.$refs.goodTable.selectedRows.length >= 1;
          this.getCheckedList();
          this.selected = this.checkedList.length > 0;
        },
        specFirmware:function(){
          // this.$emit("specFirmwareSave",this.$refs.goodTable.selectedRows.map(function(item){
          //   return item.id;
          // }))
          this.$emit("specFirmwareSave",this.checkedList.map(function(item){
            return item.id;
          }))
        },
        goodPaging: function (isPageChange) {
          if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
          this.isLoading = true;
          this.axios.post('/api/device/searchGatewayListPaging', {'gwSpecFirmwareId':this.firmwareId,'pagingForm':this.pagingParams}, {headers: {'Content-Type': 'application/json'}}).then(response => {
            this.totalRecords = response.data.result.totalElements;
            this.rows = response.data.result.content;
            //ページ件数
            this.pages = response.data.result.totalPages;
            this.topLeft();
          }).catch(error => console.log(error)
          ).finally(() => this.isLoading = false);
        }
      },
      watch:{
        isShow:function(){
          if(this.isShow){
            this.goodPaging();
          }else{
            this.pagingParams.keyword = null;
            this.rows = [];
          }
        }
      }
    }

</script>
